import React from "react";

import "../assets/css/contactlist.css";

import respontMono from "../assets/icon-mono.jpg";
import respont from "../assets/icon.jpg";

class ContactCard extends React.Component {
  render() {
    return (
      <div className={`contact-list anti-block ${this.props.className}`} style={this.props.style}>
        <div className="card rounded">
          <div className="list rounded mb-4">
            <img src={respont} alt="User" />
            <div className="content">
              <p className="mb-0">Respont Team</p>
              <span className="text-muted preview">
                Welcome to our revolutionary platform that's changing the way
                you communicate.
              </span>
            </div>
          </div>
          <div className="list rounded mb-4">
            <img src={respontMono} alt="User" />
            <div className="content">
              <p className="mb-0">Noah Montgomery</p>
              <span className="text-muted preview">
                Privacy is a concern for many, but what's the solution?
              </span>
            </div>
          </div>
          <div className="list rounded">
            <img src={respontMono} alt="User" />
            <div className="content">
              <p className="mb-0">Liam Anderson</p>
              <span className="text-muted preview">
                How they could change your messaging experience?
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactCard;
