import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import respont from "../assets/respont.png";

class Header extends React.Component {
  render() {
    return (
      <Navbar collapseOnSelect expand="lg" bg="" variant="light">
        <div className="container">
          <Navbar.Brand href="/" className="mt-2">
            <img src={respont} alt="Respont" class="respont" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <a href="#solutions" className="text-dark mb-2 mt-3 mx-3">
                Solutions
              </a>
              <a
                href="https://docs.respont.net"
                target="_blank"
                rel="noreferrer"
                className="text-dark mb-2 mt-3 mx-3"
              >
                Documentation
              </a>
              <a
                href="https://app.respont.net/"
                className="btn-header btn mx-3"
                target="_blank"
                rel="noreferrer"
              >
                Alpha App
              </a>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    );
  }
}

export default Header;
