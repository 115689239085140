import React from "react";

import "../assets/css/developercard.css";

class DeveloperCard extends React.Component {
  render() {
    return (
      <div className={`developer-card anti-block ${this.props.className}`}>
        <div className="header">
          <div className="window-action">
            <div className="bg-danger"></div>
            <div className="bg-warning"></div>
            <div className="bg-success"></div>
          </div>
        </div>
        <div className="body">
          <p>
            <span className="text-secondary">1</span> const respont = new
            Respont();
          </p>
          <p>
            <span className="text-secondary">2</span> respont.notify("vb.eth",
            "Swap Succeed", 100);
          </p>
        </div>
      </div>
    );
  }
}

export default DeveloperCard;
