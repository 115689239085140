import React from "react";

import "../assets/css/chatcard.css";

import respont from "../assets/icon.jpg";
import user from "../assets/demo-user.jpeg";

class ChatCard extends React.Component {
  state = {
    messages: [],
  };

  addMessage = (message) => {
    if (!message) return false;

    let messages = this.state.messages;
    messages.push(message);
    this.setState({
      messages,
      message: "",
    });
  };

  render() {
    return (
      <>
        <div className={`card rounded ${this.props.className}`}>
          <div className="user anti-block">
            <img
              src={user}
              alt="Lego User"
              className="d-inline rounded-circle"
            />
            <div className="d-inline mx-3">&nbsp;</div>
            <hr />
          </div>
          <div className="dialogs">
            <div className="dialog sender anti-block">
              <div className="row">
                <div className="col-9">
                  <span>
                    Have you heard about our revolutionary decentralized
                    communication app?
                  </span>
                </div>
                <div className="col-3"></div>
              </div>
            </div>
            <div className="dialog receiver anti-block">
              <div className="row">
                <div className="col-3"></div>
                <div className="col-9">
                  <span>No, tell me more about it.</span>
                </div>
              </div>
            </div>
            <div className="dialog sender anti-block">
              <div className="row">
                <div className="col-9">
                  <span>
                    Our app named Respont is all about empowering secure
                    communication. With advanced encryption and decentralized
                    network architecture, your conversations are kept private
                    and protected from unauthorized access.
                  </span>
                </div>
                <div className="col-3"></div>
              </div>
            </div>
            {this.state.messages.map((message) => {
              return (
                <div className="dialog receiver">
                  <div className="row">
                    <div className="col-3"></div>
                    <div className="col-9">
                      <span>{message}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {this.props.respontMark ? (
            <input
              type="text"
              placeholder="Enter to send a message"
              className="d-inline"
              value={this.state.message}
              onChange={(event) =>
                this.setState({ message: event.target.value })
              }
              onKeyPress={(event) =>
                event.key === "Enter" &&
                this.state.message.replace(/\s/g, "").length > 0
                  ? this.addMessage(this.state.message)
                  : null
              }
            />
          ) : null}
        </div>
        {this.props.respontMark ? (
          <div className="respont-widget rounded-circle anti-block">
            <img src={respont} alt="Respont" />
          </div>
        ) : null}
      </>
    );
  }
}

export default ChatCard;
