import React from "react";

import "../assets/css/footer.css";

import respont from "../assets/respont.png";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-3 respont-logo">
              <img src={respont} alt="Respont" class="respont" />
            </div>
            <div className="col-md-9 contact">
              <a
                href="https://github.com/therespont"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-github"></i>
              </a>
              <a
                href="https://twitter.com/respont_"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-twitter"></i>
              </a>
              <a
                href="mailto:support@respont.net"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-envelope-fill"></i>
              </a>
            </div>
          </div>
          <p className="copyright">&copy; Respont 2023. All rights reserved.</p>
        </div>
      </div>
    );
  }
}

export default Footer;
