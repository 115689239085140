import React from "react";

import "../assets/css/chatlist.css";

import respont from "../assets/icon.jpg";

class ChatList extends React.Component {
  render() {
    return (
      <div className={`chat-list anti-block ${this.props.className}`}>
        <div className="card rounded">
          <div className="list rounded">
            <img src={respont} alt="User" />
            <div className="content">
              <p>
                Respont <span className="text-muted">respont.net</span>
              </p>
              <span className="text-muted">
                You just received 2 ETH from vb.eth.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatList;
