import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as FontAwesome from "@fortawesome/free-solid-svg-icons";

import ChatCard from "../components/ChatCard";
import ChatList from "../components/ChatList";
import DeveloperCard from "../components/DeveloperCard";
import ContactCard from "../components/ContactCard";

class Home extends React.Component {
  componentDidMount() {
    document.title = "Respont | Secure messaging, decentralized future.";
  }

  render() {
    return (
      <>
        <div className="container">
          <div className="section-one pb-5">
            <div className="row">
              <div className="col-md-7">
                <div className="intro">
                  <h1 className="font-PoppinsBold">
                    EMPOWERING SECURE COMMUNICATION THROUGH DECENTRALIZATION
                    TECHNOLOGY.
                  </h1>
                  <h5 className="text-secondary">
                    Harness the strength of secure communication. Embrace data
                    control, connect fearlessly.
                  </h5>
                  <input
                    type="email"
                    placeholder="Type your email address"
                    className="shadow-sm"
                  />
                  <button className="rounded">Subscribe</button>
                </div>
              </div>
              <div className="col-md-5">
                <div className="intro">
                  <ChatCard respontMark />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-two container" id="roles">
          <div className="row">
            <div className="col-md-6">
              <div className="container mb-4">
                <ChatList />
                <h4 className="fw-bold">For General</h4>
                <p className="text-muted">
                  Unlock Seamless Two-Way Communication Across Chains. Our
                  decentralized communication app revolutionizes the way you
                  interact, facilitating secure and private conversations for
                  and from any chain. Take charge of your communication and data
                  privacy.
                </p>
                <a
                  href="https://app.respont.net/"
                  alt="Respont App"
                  className="btn rounded"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>&#945;</b> Try App
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="container developers">
                <DeveloperCard />
                <h4 className="fw-bold">
                  For Developers{" "}
                  <FontAwesomeIcon
                    icon={FontAwesome.faCode}
                    className="text-success"
                  />
                </h4>
                <p className="text-muted">
                  Unlock the potential for developers. Seamlessly integrate
                  two-way communication into your applications, empowering users
                  with secure and decentralized connectivity. Connect to our
                  endpoint and leverage our SDK to enable activity tracking and
                  take control of user communication after any actions.
                </p>
                <a
                  href="https://docs.respont.net/"
                  alt="Respont SDK Documentation"
                  className="btn rounded"
                  target="_blank"
                  rel="noreferrer"
                >
                  Documentation
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section-three container mt-5 pt-5 mb-5 pb-5"
          id="solutions"
        >
          <h5 className="text-primary text-uppercase text-center">Solutions</h5>
          <h1 className="text-center font-PoppinsSemi">
            Your Ideal Chat Messaging Platform{" "}
            <br className="d-none d-lg-block" />
            for You Communication.
          </h1>
          <p className="text-muted text-center mt-4">
            Unlock a Spectrum of Features with Our Blockchain-Powered Chat
            Messaging Solution to
            <br className="d-none d-lg-block" /> Enhance Your Communication
            Experience.
          </p>
          <div className="row mt-5 pt-5">
            <div className="col-md-6">
              <div className="container">
                <div className="p-3 border rounded card-collapse mb-2">
                  <p
                    className="fw-bold mb-0"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseManagement"
                    aria-controls="collapseManagement"
                  >
                    Chat Management
                  </p>
                  <div className="collapse show mt-4" id="collapseManagement">
                    <p className="font-PoppinsLight">
                      Designed to streamline and enhance your messaging
                      experience. With this feature-rich tool, you can
                      efficiently organize, monitor, and optimize your
                      conversations in real-time.
                    </p>
                  </div>
                </div>
                <div className="p-3 border rounded card-collapse mb-2">
                  <p
                    className="fw-bold mb-0"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFile"
                    aria-controls="collapseFile"
                  >
                    File Sharing
                  </p>
                  <div className="collapse mt-4" id="collapseFile">
                    <p className="font-PoppinsLight">
                      Offers a seamless and secure way to exchange files of any
                      size or type, powered by cutting-edge IPFS (InterPlanetary
                      File System) technology. With user-friendly features, you
                      can effortlessly upload, share, and access files from
                      anywhere, on any device. IPFS integration enhances
                      security and ensures data availability, making your file
                      sharing experience even more reliable.
                    </p>
                  </div>
                </div>
                <div className="p-3 border rounded card-collapse mb-2">
                  <p
                    className="fw-bold mb-0"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseCollaboration"
                    aria-controls="collapseCollaboration"
                  >
                    Notification & Alert
                  </p>
                  <div className="collapse mt-4" id="collapseCollaboration">
                    <p className="font-PoppinsLight">
                      Offers real-time communication with a unique twist - it
                      incorporates on-chain integration for added security and
                      reliability. Stay informed with instant updates and alerts
                      directly from the blockchain, ensuring that critical
                      information reaches you promptly and securely. Whether
                      it's transaction confirmations, security alerts, or
                      important announcements, our solution delivers on-chain
                      notifications to your preferred device, keeping you in the
                      loop at all times.
                    </p>
                  </div>
                </div>
                <div className="p-3 border rounded card-collapse mb-2">
                  <p
                    className="fw-bold mb-0"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseIntegration"
                    aria-controls="collapseIntegration"
                  >
                    Integration
                  </p>
                  <div className="collapse mt-4" id="collapseIntegration">
                    <p className="font-PoppinsLight">
                      Solution takes communication to the next level by
                      seamlessly integrating with Software Development Kits
                      (SDKs). This integration allows you to harness the full
                      power of our platform and customize notifications and
                      alerts to your specific needs. Stay connected with
                      real-time updates, transaction confirmations, security
                      alerts, and essential announcements, all delivered through
                      our SDK integration
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 pt-5">
              <div className="container">
                <div className="components">
                  <ContactCard className="contact-component" />
                  <ChatCard className="w-lg-100 chat-component ms-lg-5" />
                  <DeveloperCard className="w-lg-75 developer-component" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-four container text-center shadow-sm">
          <h5 className="text-primary text-uppercase text-center">Try Now</h5>
          <h1 className="text-center font-PoppinsSemi mb-3">
            Begin with Ease.
          </h1>
          <p className="text-center">
            Quickly access all the features and benefits waiting for you.
          </p>
          <a
            href="https://app.respont.net"
            target="_blank"
            rel="noreferrer"
            className="btn btn-warning rounded-pill p-3 px-5 mt-3 font-PoppinsSemi"
          >
            Discover What's New with Alpha Project{" "}
            <i className="bi bi-arrow-right ms-2"></i>
          </a>
        </div>
      </>
    );
  }
}

export default Home;
